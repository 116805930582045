<template>
  <VForm
    v-slot="{ handleSubmit, isSubmitting}" as="div"
    class="page standard vertical-form pos_portfolio_form_container"
  >
    <section class="header">
      <div>
        <h1>{{ $t('admin.admins.detail.title') }}</h1>
        <p class="subtitle">{{$t('admin.admins.detail.subtitle')}}</p>
      </div>      
      <div class="actions">
        <Button
          :label="$t('btn_save_label')"
          class="p-button-success"
          icon="pi pi-save"
          iconPos="right"
          :disabled="requestPending"
          type="submit"
          form="edit_form"
        ></Button>

      </div>
    </section>

    <section class="content card">
      <h3 v-if="requestPending && !user">{{ $t('loading_label') }}</h3>
      <form v-if="user" class="form_content" @submit="handleSubmit($event, doSave)" id="edit_form">
        <div class="p-field p-col-6">
          <label>
            {{$t('user_name')}}
            <InfoIcon text="user_name.tooltip" />
          </label>

          <VField
            autofocus
            name="name"
            v-model="user.name"
            rules="required"
            v-slot="{ field, meta: { dirty, valid }}"
          >
            <InputText
              v-bind="field"
              :disabled="isSubmitting"
              :class="{ 'p-invalid': dirty && !valid }"
              class="p-input-filled"
              :placeholder="$t('user_name')"
            />
          </VField>
        </div>
        <div class="p-field p-col-6">
          <label>
            {{$t('user_surname')}}
            <InfoIcon text="user_surname.tooltip" />
          </label>
          <VField
            name="surname"
            v-model="user.surname"
            rules="required"
            v-slot="{ field, meta: { dirty, valid }}"
          >
            <InputText
              v-bind="field"
              :disabled="isSubmitting"
              :class="{ 'p-invalid': dirty && !valid }"
              class="p-input-filled"
              :placeholder="$t('user_surname')"
            />
          </VField>
        </div>
        <div class="p-field p-col-6">
          <label>
            {{$t('generic.email_label')}}
            <InfoIcon text="generic.email_label.tooltip" />
          </label>
          <VField
            name="email"
            v-model="user.email"
            rules="required|email"
            v-slot="{ field, meta: { dirty, valid }}"
          >
            <InputText
              v-bind="field"
              :disabled="isSubmitting"
              :class="{ 'p-invalid': dirty && !valid }"
              class="p-input-filled"
              :placeholder="$t('generic.email_label')"
            />
          </VField>
        </div>
        <div class="p-field p-col-6">
          <label>
              {{ $t('generic.input_state_label') }}
              <InfoIcon text="generic.input_state_label.tooltip"/>
          </label>
          <div class="p-field-radio">
              <div class="p-field-radiobutton">
                  <RadioButton id="is_active_on" v-model="user.is_active" :value="true" />
                  <label for="is_active_on">{{ $t('active_label') }}</label>
              </div>
              <div class="p-field-radiobutton">
                  <RadioButton
                      id="is_active_off"
                      v-model="user.is_active"
                      :value="false"
                  />
                  <label for="is_active_off">{{ $t('inactive_label') }}</label>
              </div>
          </div>
        </div>
      </form>
    </section>

    <section class="footer">
      <Button
        :label="$t('btn_go_back_label')"
        class="p-button-secondary"
        icon="pi pi-arrow-left"
        iconPos="left"
        @click="$router.back()"
      ></Button>
      <Button
        :label="$t('btn_save_label')"
        class="p-button-success p-ml-auto"
        icon="pi pi-save"
        iconPos="right"
        :disabled="requestPending"
        type="submit"
        form="edit_form"
      ></Button>
    </section>
  </VForm>
</template>

<script src="./AdminEditPage" />