import { PaginatedResponse } from '@/model/api/PaginatedResponse';
import { User } from '@/model/api/User';
import { CRUD } from './base/crud';

class AdminsService extends CRUD<User, any> {
    readonly endPoint = `admins`;

    public autocomplete(q: string): Promise<PaginatedResponse<User>> {
        return this.get(`${this.endPoint}/autocomplete`, { params: { q: q } });
    }

    public getById(id: number){
        return this.get<User>(`${this.endPoint}/${id}`);
    }    
}

export const adminsService = new AdminsService();



