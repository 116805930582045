import { POSRoutesEnum } from '@/modules/pos/router';
import { ServicesRoutesEnum } from '@/modules/services/router';
import { TechniciansRoutesEnum } from '@/modules/technicians/router';
import { Options, Vue } from 'vue-class-component';
import { UsersRoutesEnum } from '../router';

@Options({
})
export default class UsersMainPage extends Vue {

    goProviders() {
        this.$router.push({ name: UsersRoutesEnum.PROVIDER_LIST });
    }

    goPOS() {
        this.$router.push({ name: POSRoutesEnum.LIST });
    }

    goAdmins() {
        this.$router.push({ name: UsersRoutesEnum.ADMIN_LIST });
    }

    goCustomers() {
        this.$router.push({ name: UsersRoutesEnum.USER_LIST });
    }

    get techniciansRoute() {
        return {
            name: TechniciansRoutesEnum.ADMIN_TECHNICIAN_LIST,
        }
    }
    get networksRoute() {
        return {
            name: ServicesRoutesEnum.NETWORK_LIST,
        }
    }
    get sponsorsRoute() {
        return {
            name: ServicesRoutesEnum.SPONSORS_LIST,
        }
    }
}