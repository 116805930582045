import { User }               from "@/model/api/User";
import { adminsService }       from "@/services/admins.service";
import { FilterMatchMode }    from "primevue/api";
import { Options, Vue }       from "vue-class-component";
import { UsersRoutesEnum }    from "../../router";

import {
  UserDynamicTable,
  MyAutocomplete
} from "@components";

@Options({
  components: {
    UserDynamicTable,
    MyAutocomplete
  }
})

export default class AdminListPage extends Vue {
  readonly stateTableKey = 'AdminListPage';

  filters: any = this.filtersSchema;

  get datatable(): any {
    return this.$refs.datatable;
  }

  beforeRouteEnter(to, from, next) {
    if (from.name
      && !(from.name as string).startsWith(UsersRoutesEnum.ADMIN_PREFIX)) {
        sessionStorage.removeItem('AdminListPage');
    }
    next();
  }
  
  beforeRouteLeave() {
    if (adminsService?.cancelPendingRequests) {
      adminsService.cancelPendingRequests();
    }
  }

  get service() {
    return adminsService;
  }

  goAdd() {
    this.$router.push({ name: UsersRoutesEnum.ADMIN_EDIT, params: { adminId: 0 } });
  }

  goToDetail(model: User) {
    this.$router.push({ name: UsersRoutesEnum.ADMIN_EDIT, params: { adminId: model.id } });
  }

  applyFilters() {
    this.datatable.loadData();
  }

  get cols(){
    return [
      {
        field: 'name',
        header: this.$t('user_name'),
      },
      {
        field: 'surname',
        header: this.$t('user_surname'),
      },
      {
        field: 'email',
        header: this.$t('user_email'),
      },
      {
        field: 'is_active',
        header: this.$t('generic.input_state_label'),
        style: 'min-width: 10px; max-width: 100px'
      }
    ]
  }

  get filtersSchema() {
    return {
      name: {
        matchMode: FilterMatchMode.STARTS_WITH
      },
      surname: {
        matchMode: FilterMatchMode.EQUALS
      },
      email: {
        matchMode: FilterMatchMode.STARTS_WITH
      },
      is_active: {
        matchMode: FilterMatchMode.EQUALS
      },
    };
  }

  get filterActiveOptions() {
    return [
      { name: this.$t('generic.all_sing_male_label'), value: null },
      { name: this.$t('active_label'), value: true },
      { name: this.$t('inactive_label'), value: false },
    ];
  }
}
