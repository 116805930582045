import { Options, Vue } from "vue-class-component";

import {
  ActiveCell,
  UserDynamicTable,
  MyAutocomplete
} from "@components";

import { UsersRoutesEnum } from "../../router";
import { providerService } from "@services/provider.service";
import { Provider } from "@/model/api/Provider";
import { FilterMatchMode } from "primevue/api";

@Options({
  components: {
    UserDynamicTable,
    MyAutocomplete
  }
})
export default class ProviderListPage extends Vue {
  readonly stateTableKey = 'ProviderListPage';

  filters: any = this.filtersSchema;

  get datatable(): any {
    return this.$refs.datatable;
  }

  beforeRouteLeave() {
    if (providerService?.cancelPendingRequests) {
      providerService.cancelPendingRequests();
    }
  }

  beforeRouteEnter(to, from, next) {
    if (from.name && !(from.name as string).startsWith(UsersRoutesEnum.PROVIDER_PREFIX)) {
      sessionStorage.removeItem('ProviderListPage');
    }
    next();
  }

  get service() {
    return providerService;
  }

  get filterActiveOptions() {
    return [
      { name: this.$t('generic.all_sing_male_label'), value: null },
      { name: this.$t('active_label'), value: true },
      { name: this.$t('inactive_label'), value: false },
    ];
  }

  goAdd() {
    this.$router.push({ name: UsersRoutesEnum.PROVIDER_EDIT, params: { providerId: 0 } });
  }

  goToDetail(model: Provider) {
    this.$router.push({ name: UsersRoutesEnum.PROVIDER_EDIT, params: { providerId: model.id } });
  }

  applyFilters() {
    this.datatable.loadData();
  }

  get cols() {
    return [
      {
        field: 'business_name',
        header: this.$t('generic.business_name_label')
      },
      /*
      {
        field: 'email',
        header: this.$t('generic.email_label'),
      },
      */
      {
        field: 'city_id',
        header: this.$t('generic.city_label')
      },
      {
        field: 'province_id',
        header: this.$t('generic.province_label')
      },
      /*
      {
        field: 'region_id',
        header: this.$t('generic.region_label')
      },
      {
        field: 'country_id',
        header: this.$t('generic.country_label')
      },
      {
        field: 'foreing_province',
        header: this.$t('generic.foreign_province_label')
      },
      {
        field: 'zip',
        header: this.$t('generic.zip_label')
      },
      */
      {
        field: 'is_contract_active',
        header: this.$t('admin.providers.detail.contract_status_label'),
        component: ActiveCell,
        style: 'min-width: 10px; max-width: 150px'
      },
      {
        field: 'is_active',
        header: this.$t('generic.input_state_label'),
        component: ActiveCell,
        style: 'min-width: 10px; max-width: 150px'
      }
    ];
  }

  get filtersSchema() {
    return {
      business_name: {
        matchMode: FilterMatchMode.STARTS_WITH
      },
      email: {
        matchMode: FilterMatchMode.STARTS_WITH
      },
      city_id: {
        matchMode: FilterMatchMode.EQUALS
      },
      province_id: {
        matchMode: FilterMatchMode.EQUALS
      },
      region_id: {
        matchMode: FilterMatchMode.EQUALS
      },
      country_id: {
        matchMode: FilterMatchMode.EQUALS
      },
      foreing_province: {
        matchMode: FilterMatchMode.STARTS_WITH
      },
      zip: {
        matchMode: FilterMatchMode.EQUALS
      },
      is_contract_active: {
        matchMode: FilterMatchMode.EQUALS
      },
      is_active: {
        matchMode: FilterMatchMode.EQUALS
      }
    };
  }
}
