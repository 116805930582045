import { Options, Vue } from 'vue-class-component';
import { AdminStatsRoutesEnum } from '@/modules/adminstats/router';

@Options({
})
export default class AdminStatsMainPage extends Vue {

    goPosNewServices() {
        this.$router.push({ name: AdminStatsRoutesEnum.ADMS_POS_NEWSERVICES });
    }

    goPosBuyedServicesGeo() {
        this.$router.push({ name: AdminStatsRoutesEnum.ADMS_POS_GEOBUYEDSERVICES });
    }

    goCustomerBuyedServicesGeo() {
        this.$router.push({ name: AdminStatsRoutesEnum.ADMS_CUSTOMER_GEOBUYEDSERVICES });
    }

    /*
    goProviders() {
        this.$router.push({ name: AdminStatsRoutesEnum.PROVIDER_LIST });
    }

    goCustomers() {
        this.$router.push({ name: AdminStatsRoutesEnum.USER_LIST });
    }
    */

}