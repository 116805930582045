import { User }               from "@/model/api/User";
import { FilterMatchMode }    from "primevue/api";
import { Options, Vue }       from "vue-class-component";
import { UsersRoutesEnum }    from "../../router";
import { usersService }       from "@services/users.service";
import {
  UserDynamicTable,
  MyAutocomplete
} from "@components";

@Options({
  components: {
    UserDynamicTable,
    MyAutocomplete
  }
})

export default class UserListPage extends Vue {
  readonly stateTableKey = 'UserListPage';

  filters: any = this.filtersSchema;

  get datatable(): any {
    return this.$refs.datatable;
  }

  beforeRouteEnter(to, from, next) {
    if (from.name
      && !(from.name as string).startsWith(UsersRoutesEnum.USER_PREFIX)) {
        sessionStorage.removeItem('UserListPage');
    }
    next();
  }
  
  beforeRouteLeave() {
    if (usersService?.cancelPendingRequests) {
      usersService.cancelPendingRequests();
    }
  }

  get service() {
    return usersService;
  }

  goAdd() {
    this.$router.push({ name: UsersRoutesEnum.USER_EDIT, params: { userId: 0 } });
  }

  goToDetail(model: User) {
    this.$router.push({ name: UsersRoutesEnum.USER_EDIT, params: { userId: model.id } });
  }

  applyFilters() {
    this.datatable.loadData();
  }

  get cols() {
    return [
      {
        field: 'name',
        header: this.$t('user_name'),
      },
      {
        field: 'surname',
        header: this.$t('user_surname'),
      },
      {
        field: 'email',
        header: this.$t('user_email'),
      },
      {
        field: 'fiscal_code',
        filterField: 'fiscal_code',
        header: this.$t('generic.fiscal_code_label'),
      },
      {
        field: 'is_active',
        header: this.$t('generic.input_state_label'),
        style: 'min-width: 10px; max-width: 100px'
      }
    ]
  }

  get filtersSchema() {
    return {
      name: {
        matchMode: FilterMatchMode.STARTS_WITH
      },
      surname: {
        matchMode: FilterMatchMode.EQUALS
      },
      email: {
        matchMode: FilterMatchMode.STARTS_WITH
      },
      fiscal_code: {
        matchMode: FilterMatchMode.STARTS_WITH
      },
      is_active: {
        matchMode: FilterMatchMode.EQUALS
      },
    };
  }

  get filterActiveOptions() {
    return [
      { name: this.$t('generic.all_sing_male_label'), value: null },
      { name: this.$t('active_label'), value: true },
      { name: this.$t('inactive_label'), value: false },
    ];
  }
}
