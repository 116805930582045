import { Provider } from '@/model/api/Provider';

import { providerService } from '@services/provider.service';
import { classToPlain, plainToClass } from 'class-transformer';
import { FormPage, ProviderForm } from '@components';

import {
  Form as VForm,
  Field as VField
} from 'vee-validate';
import { Options, Vue } from "vue-class-component";
import { Prop } from 'vue-property-decorator';
import { TechniciansRoutesEnum } from '@/modules/technicians/router';

@Options({
  components: {
    VForm,
    VField,
    ProviderForm
  }
})
export default class ProviderEditPage extends FormPage {
  @Prop() readonly providerId!: number;

  model: Provider = null;

  isLoading = false;

  get isNew() {
    return this.providerId == 0;
  }

  beforeRouteLeave(to, from, next) {
    this.checkBeforeRouteLeave(next);
  }

  async created() {
    if (this.isNew) {
      this.model = new Provider();
      this.model.is_active = true;
    } else {
      this.isLoading = true;
      try {
        this.model = plainToClass(Provider, await providerService.getById(this.providerId));
      } catch (e) {
        this.$router.back();
      }
      this.isLoading = false;
    }

    this.modelFormChanged();
  }

  async doSave() {
    await this.$waitFor(
      async () => {
        if (this.isNew) {
          await providerService.create(classToPlain(this.model) as any);
        } else {
          await providerService.updatePatch(classToPlain(this.model) as any);
        }
        this.modelFormChanged();

        this.$successMessage(this.$t('data_saved_success'));
        this.$router.back();
      },
      this.$t('generic_error')
    );
  }

  goTechnicians() {
    this.$router.push({ name: TechniciansRoutesEnum.TECHNICIAN_LIST, params: { providerId: this.model.id } });
  }

}