import { User }               from "@/model/api/User";
import { adminsService }      from "@/services/admins.service";
import { Options, Vue }       from "vue-class-component";
import { Prop }               from "vue-property-decorator";
import { UsersRoutesEnum }    from "../../router";
import { authStore }          from "@/modules/auth/store";
import { UserStatusEnum } from "@/model/enums/UserStatusEnum";
import { FormPage } from '@components';
import {
  Form as VForm,
  Field as VField,
  ErrorMessage as VError
} from 'vee-validate';
import { UserRoleEnum } from "@/model/enums/UserRoleEnum";

@Options({
  components: {
    VForm,
    VField,
    VError
  }
})

export default class AdminEditPage extends FormPage {
  @Prop() readonly adminId!: number;

  isLoading: boolean = false;

  user: User = null;
  confirmPassword: string = null;

  get currentRole(){
    return authStore.getters.userRole;
  }

  get statusOptions() {
    return [
      { label: 'CREATED',   value: UserStatusEnum.CREATED  },
      { label: 'ACTIVE',    value: UserStatusEnum.ACTIVE },
      { label: 'DISABLED',  value: UserStatusEnum.DISABLED },
    ]
  }

  get isNew() {
    return this.adminId == 0;
  }

  get backRoute() {
    return { name: UsersRoutesEnum.ADMIN_LIST };
  }


  async loadUser() {
    this.$waitFor(
      async () => {
        this.user = await adminsService.getById(this.adminId)
      }
    );
  }

  private deleteUser() {
    const message = this.$t(
      'user.delete_message',
      { name: `${this.user.name} ${this.user.surname}` }
    );

    const header = this.$t('message.confirm');

    this.$confirmMessage(message, header)
      .then((r) => {
        if (r) {

          this.$waitFor( async () => {
            await adminsService.remove(this.user);

            this.$router
            .replace(this.backRoute)
            .then( () => this.$successMessage("User succesfully deleted") );
          });
        }
      });
  }

  async doSave() {
    await this.$waitFor(
      async () => {
        if (this.isNew) {
          await adminsService.create(this.user);
        } else {
          await adminsService.update(this.user);
        }
        this.modelFormChanged();

        this.$successMessage(this.$t('data_saved_success'));
        this.$router.back();
      },
      this.$t('generic_error')
    )
  }

  onDelete() {
    this.deleteUser();
  }

  async created() {
    if (!this.isNew) {
      this.isLoading = true;
      try {
        this.user = await adminsService.getById(this.adminId)        
      } catch (e) {
        this.$router.back();
      }
      this.isLoading = false;
    } else {
      this.user = new User();
      this.user.role = UserRoleEnum.SUPER_ADMIN;
      this.user.is_active = true;
    }

    this.modelFormChanged();
  }

}